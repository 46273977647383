import React from "react";
import Layout from "./src/components/layout";
import { UseWalletProvider } from "use-wallet";
import "./src/scss/index.scss";

// Wraps page root element
export const wrapRootElement = ({ element }) => {
  const chainId = parseInt(process.env.GATSBY_ETHERNET_CHAIN_ID);

  console.log(
    "process.env.GATSBY_APP_ETHEREUM_RPC",
    process.env.GATSBY_APP_ETHEREUM_RPC
  );
  return (
    <UseWalletProvider
      autoConnect
      connectors={{
        walletconnect: {
          chainId: chainId,
          rpc: { [chainId]: process.env.GATSBY_APP_ETHEREUM_RPC },
        },
      }}
    >
      {element}
    </UseWalletProvider>
  );
};

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
